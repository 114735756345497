export {Icon, iconSet as baseIconSet} from './Icon';
export {Icon as IconSmall, iconSet as iconSmallSet} from './IconSmall';
export {Icon as IconSocial, iconSet as iconSocialSet} from './Social';
export {
  Icon as IconPaymentMethod,
  iconSet as iconPaymentMethodSet,
} from './PaymentMethod';
export {LogoColored, type IconName as LogoColoredIconName} from './LogoColored';
export {LoyaltyIcon, type IconName as LoyaltyIconName} from './Loyalty';
export {SectorsIcon, type IconName as SectorsIconName} from './Sectors';
export {
  IconSectorsLogo,
  type IconName as IconSectorsLogoName,
} from './IconSectorsLogo';

export {DeviceIcon, iconSet as deviceIconSet} from './Device';

export type {IconName as BaseIconName} from './Icon';
export type {IconName as IconSmallName} from './IconSmall';
export type {IconName as IconSocialName} from './Social';
export type {IconName as IconPaymentMethodName} from './PaymentMethod';
export type {IconName as DeviceIconName} from './Device';

export {createIconComponent} from './createIcon';

export type {IconProps} from './createIcon';
